import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

interface Props {
  id?: string;
  position?: string;
}

async function openDoor(position: string) {
  const x = position[0];
  const y = position.substring(1);
  const url = `http://127.0.0.1:5000/?xpos=${x}&ypos=${y}`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return;
}

export default function useOpenDoor(options?: UseMutationOptions) {
  const queryClient = useQueryClient();

  return useMutation(async ({ id, position }: Props) => {
    let door_position = position;

    if (id) {
      const expediboxLocker = queryClient.getQueryData(
        "expediboxLocker"
      ) as ExpediboxLocker;

      if (!expediboxLocker) {
        alert("Can't find locker data");
        return;
      }

      const door = expediboxLocker.doors.find((door) => door.id === id);

      if (!door) {
        alert("Can't find door id");
        return;
      }

      door_position = door.position;
    }

    if (!door_position) {
      alert("Can't find door");
      return;
    }

    return openDoor(door_position);
  }, options as any);
}
