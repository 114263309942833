import { QueryClient, QueryClientProvider } from "react-query";
import { MemoryRouter, BrowserRouter } from "react-router-dom";
import { Router } from "./Router";

import "./App.scss";

const FIVE_MINUTES = 5 * 60 * 1000;

export function App() {
  let RouterProvider = MemoryRouter;
  if (process.env.NODE_ENV === "development") RouterProvider = BrowserRouter;

  return (
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              refetchOnWindowFocus: false,
              refetchOnReconnect: false,
              cacheTime: Infinity,
              staleTime: FIVE_MINUTES,
            },
          },
        })
      }
    >
      <RouterProvider>
        <Router />
      </RouterProvider>
    </QueryClientProvider>
  );
}
