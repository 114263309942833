import { Container } from "react-bootstrap";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";

export function LoanReturn() {
  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title="Retour complété" />

      <Container className="mt-5 p-3 w-75">
        <div className="mt-5 border-start border-3 rounded border-primary bg-surface-variant text-on-surface text-center shadow px-5 py-4">
          <div className="display-4 fw-light mb-2">
            Merci <i className="bi bi-patch-check ms-2 text-secondary"></i>
          </div>

          <p className="fw-light fs-4">Vous pouvez retourner votre outil.</p>
        </div>
      </Container>

      <Footer showHome />
    </div>
  );
}
