import { useMutation, UseQueryOptions } from "react-query";
import { getConfig } from "../config";

async function createLockerItem(data: LockerItemCreate) {
  const { expediboxApiUrl, expediboxApiKey } = getConfig();
  let url = `${expediboxApiUrl}/locker_items/`;
  const headers = {
    "X-App-Token": expediboxApiKey,
    "Content-Type": "application/json",
  };
  const response = await fetch(url, {
    headers,
    method: "POST",
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error("Network Error");
  }
}

export function useCreateLockerItem(options?: UseQueryOptions) {
  return useMutation(
    (data: LockerItemCreate) => createLockerItem(data),
    options as any
  );
}
