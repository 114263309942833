import { useMutation, UseMutationOptions } from "react-query";
import { getConfig } from "../config";

export async function createRepair(data: RepairCreate): Promise<Repair> {
  const { apiUrl, apiKey } = getConfig();
  const url = `${apiUrl}/repairs/`;
  const headers = {
    "X-Locker-Token": apiKey,
    "Content-Type": "application/json",
  };
  const response = await fetch(url, {
    headers,
    method: "POST",
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export function useCreateRepair(options?: UseMutationOptions) {
  return useMutation(
    (data: RepairCreate) => createRepair(data),
    options as any
  );
}
