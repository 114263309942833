import { useQuery, UseQueryOptions } from "react-query";
import { getConfig } from "../config";

async function getRefillMinimums(): Promise<RefillMinimum[]> {
  const { apiUrl, apiKey } = getConfig();
  const url = `${apiUrl}/lockers/refill-minimums/`;
  const headers = { "X-Locker-Token": apiKey };
  const response = await fetch(url, { headers });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export default function useGetRefillMinimums(options?: UseQueryOptions) {
  return useQuery("refillMinimums", getRefillMinimums, options as any);
}
