import { useMutation, UseMutationOptions } from "react-query";
import { getConfig } from "../config";

export async function createLoan(data: LoanCreate) {
  const { apiUrl, apiKey } = getConfig();
  const url = `${apiUrl}/loans/`;
  const headers = {
    "X-Locker-Token": apiKey,
    "Content-Type": "application/json",
  };
  const response = await fetch(url, {
    headers,
    method: "POST",
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export function useCreateLoan(options?: UseMutationOptions) {
  return useMutation((data: LoanCreate) => createLoan(data), options as any);
}
