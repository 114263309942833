import { useQuery, UseQueryOptions } from "react-query";
import { getConfig } from "../config";

interface Props {
  repairable?: boolean;
}

async function getProducts(props: Props = {}): Promise<Product[]> {
  const { apiUrl, apiKey } = getConfig();
  let url = `${apiUrl}/inventory/products/`;
  if (props.repairable) {
    url += "?repairable=true";
  }
  const headers = { "X-Locker-Token": apiKey };
  const response = await fetch(url, { headers });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export default function useGetProducts(
  props: Props = {},
  options?: UseQueryOptions
) {
  return useQuery(
    ["products", props],
    () => getProducts(props),
    options as any
  );
}
