import { useQuery, UseQueryOptions } from "react-query";
import { getConfig } from "../config";

async function getWorkers(number?: string): Promise<Worker[]> {
  const { apiUrl, apiKey } = getConfig();
  let url = `${apiUrl}/workforce/workers/`;
  if (number) {
    url += `?number=${number}`;
  }
  const headers = { "X-Locker-Token": apiKey };
  const response = await fetch(url, { headers });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export default function useGetWorkers(
  number?: string,
  options?: UseQueryOptions
) {
  return useQuery(
    ["workers", number],
    () => getWorkers(number),
    options as any
  );
}
