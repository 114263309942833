import { useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Numpad } from "../../components/Numpad";
import useGetCurrentLocker from "../../hooks/useGetCurrentLocker";
import { useGetLockerItems } from "../../hooks/useGetLockerItems";
import useGetRefillMinimums from "../../hooks/useGetRefillMinimum";
import useGetRepairs from "../../hooks/useGetRepairs";
import useOpenDoor from "../../hooks/useOpenDoor";
import { useUpdateRepair } from "../../hooks/useUpdateRepair";
import { RefillModal } from "./RefillModal";

function itemQuantity(items: LockerItem[], sku: string) {
  return items.find((i) => i.sku === sku)?.quantity || 0;
}

export function Refill() {
  const queryClient = useQueryClient();
  const [showRefillModal, setShowRefillModal] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [unlocked, setUnlocked] = useState(false);
  const [item, setItem] = useState<LockerItem>();
  const currentLockerQuery = useGetCurrentLocker();
  const refillQuery = useGetRefillMinimums();
  const itemsQuery = useGetLockerItems();
  const repairsQuery = useGetRepairs("RETURNED");
  const updateRepair = useUpdateRepair();
  const openDoor = useOpenDoor();

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title="Remplissage" />

      <div className="mt-3">
        {invalid && (
          <Container className="mb-2">
            <div className="border-start border-3 rounded border-danger bg-surface-variant text-on-surface text-center shadow px-5 py-2">
              <p className="fw-light fs-4 my-2">
                Code invalide. Veuillez réessayer.
              </p>
            </div>
          </Container>
        )}

        {!unlocked && currentLockerQuery.data && (
          <Container className="mt-4">
            <Numpad
              className="bg-surface-variant shadow-sm p-4 rounded"
              placeholder="Code de remplissage"
              onSubmit={(code) => {
                if (code === currentLockerQuery.data.refill_code.toString()) {
                  setInvalid(false);
                  setUnlocked(true);
                } else {
                  setInvalid(true);
                }
              }}
            />
          </Container>
        )}
      </div>

      {unlocked &&
        currentLockerQuery.data &&
        repairsQuery.data &&
        !!repairsQuery.data.length && (
          <Container className="mt-3 bg-white shadow p-4 d-flex align-items-center">
            <div className="lead flex-grow-1">
              {repairsQuery.data.length}{" "}
              {repairsQuery.data.length === 1 ? "réparation" : "réparations"} en
              attente
            </div>
            <Button
              disabled={updateRepair.isLoading}
              variant="secondary"
              onClick={async () => {
                openDoor.mutate({
                  position: currentLockerQuery.data.repair_door_position,
                });
                for (let repair of repairsQuery.data) {
                  await updateRepair.mutateAsync({
                    id: repair.id,
                    status: "CLOSED",
                  });
                }
                queryClient.invalidateQueries(["repairs", "RETURNED"]);
              }}
            >
              Vider
            </Button>
          </Container>
        )}

      <div style={{ maxHeight: "85vh", overflow: "scroll" }}>
        {unlocked &&
          refillQuery.data?.map((refill) => (
            <Container key={refill.id} className="mt-3 bg-white shadow-sm p-2">
              <Table className="mb-0 align-middle">
                <tbody>
                  <tr>
                    <td style={{ width: 45 }}>
                      <strong>{refill.door}</strong>
                    </td>
                    <td>
                      {refill.name} <code>({refill.sku})</code>
                    </td>
                    <td style={{ width: 75 }}>
                      {itemQuantity(itemsQuery?.data || [], refill.sku)}/
                      {refill.quantity}
                    </td>
                    <td className="text-end" style={{ width: 100 }}>
                      <Button
                        onClick={() => {
                          const item = itemsQuery?.data?.find(
                            (i) => i.sku === refill.sku
                          );
                          setItem(
                            item || {
                              door_id: refill.expedibox_door_id,
                              sku: refill.sku,
                              name: refill.name,
                              quantity: 0,
                            }
                          );
                          openDoor.mutate({ id: refill.expedibox_door_id });
                          setShowRefillModal(true);
                        }}
                      >
                        Ajuster
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          ))}
      </div>

      {item && (
        <RefillModal
          show={showRefillModal}
          onHide={() => setShowRefillModal(false)}
          item={item}
        />
      )}

      <Footer showBack />
    </div>
  );
}
