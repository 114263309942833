import { createRef } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Switch, useLocation, Route } from "react-router-dom";
import { Variants } from "../../pages/Variants";
import { CheckoutSuccess } from "../../pages/CheckoutSuccess/CheckoutSuccess";
import { Home } from "../../pages/Home";
import { Products } from "../../pages/Products";
import { Profile } from "../../pages/Profile";
import { Checkout } from "../../pages/Checkout/Checkout";
import { Refill } from "../../pages/Refill";
import { Return } from "../../pages/Return";
import { Truck } from "../../pages/Truck";
import { useRedirectHomeOnIdle } from "./useRedirectHomeOnIdle";
import { useRefreshWhenStale } from "./useRefreshWhenStale";
import { useGetExpediboxLocker } from "../../hooks/useGetExpediboxLocker";
import { LoanReturn } from "../../pages/LoanReturn/LoanReturn";
import { RepairableProducts } from "../../pages/RepairableProducts";
import { RepairableVariants } from "../../pages/RepairableVariants";
import { Preoloader } from "./Preloader";

export function Router() {
  const nodeRef = createRef<any>();
  const location = useLocation();
  useGetExpediboxLocker({ cacheTime: Infinity, staleTime: Infinity });
  useRedirectHomeOnIdle();
  useRefreshWhenStale();

  return (
    <Preoloader>
      <TransitionGroup>
        <CSSTransition
          nodeRef={nodeRef}
          key={location.key}
          classNames="fade"
          timeout={250}
        >
          <div ref={nodeRef}>
            <Switch location={location}>
              <Route exact path="/checkout-success">
                <CheckoutSuccess />
              </Route>
              <Route exact path="/checkout">
                <Checkout />
              </Route>
              <Route exact path="/variants">
                <Variants />
              </Route>
              <Route exact path="/products">
                <Products />
              </Route>
              <Route exact path="/profile">
                <Profile />
              </Route>
              <Route exact path="/refill">
                <Refill />
              </Route>
              <Route exact path="/return">
                <Return />
              </Route>
              <Route exact path="/truck">
                <Truck />
              </Route>
              <Route exact path="/return-loan">
                <LoanReturn />
              </Route>
              <Route exact path="/repairable-products">
                <RepairableProducts />
              </Route>
              <Route exact path="/repairable-variants">
                <RepairableVariants />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Preoloader>
  );
}
