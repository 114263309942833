import { useMemo } from "react";
import { Container, Table, Button, Badge } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { useGetLockerItems } from "../../hooks/useGetLockerItems";

interface LocationProps {
  product: Product;
  worker: Worker;
}

export function Variants() {
  const history = useHistory();
  const location = useLocation<LocationProps>();
  const product = location.state?.product;
  const worker = location.state?.worker;
  const lockerItemsQuery = useGetLockerItems();

  const availableVariants = useMemo(() => {
    if (!lockerItemsQuery.data) return;
    const skus = lockerItemsQuery.data.map((li) => li.sku);
    return product.variants.filter((v) => skus.includes(v.sku));
  }, [lockerItemsQuery.data, product.variants]);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title="Sélectionnez un item" />

      <Container className="mt-3 rounded bg-white shadow-sm p-2">
        <Table className="mb-0">
          <tbody>
            <tr className="align-middle">
              <td style={{ minWidth: 170, maxWidth: 170 }}>
                <div
                  className="rounded"
                  style={{
                    width: 150,
                    height: 150,
                    backgroundImage: `url(${product.image})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </td>
              <td className="w-100">
                <h6>
                  {product.name}{" "}
                  <small className="text-muted">{product.category?.name}</small>
                </h6>
                <p className="mb-2">{product.description}</p>
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>

      <Container
        className="mt-3 rounded bg-white shadow p-2"
        style={{ minHeight: 50, maxHeight: 615, overflow: "overlay" }}
      >
        <Table className="mb-0">
          <tbody>
            {product.variants.map((variant) => (
              <tr key={variant.id} className="align-middle">
                <td className="align-middle py-4">
                  <span className="lead me-2">{variant.name}</span>
                </td>
                <td className="text-end">
                  {availableVariants?.some((av) => av.sku === variant.sku) && (
                    <Badge className="me-4" bg="secondary">
                      Disponible
                    </Badge>
                  )}

                  {availableVariants?.some((av) => av.sku === variant.sku) &&
                    product.loanable && (
                      <Badge className="me-4" bg="info">
                        Empruntable
                      </Badge>
                    )}
                  {product.repairable && (
                    <Badge className="me-4" bg="info">
                      Réparable
                    </Badge>
                  )}

                  <Button
                    onPointerUp={() => {
                      const lockerItem = lockerItemsQuery.data?.find(
                        (li) => li.sku === variant.sku
                      );
                      history.push("/checkout", {
                        worker,
                        product,
                        variant,
                        lockerItem,
                      });
                    }}
                  >
                    Sélectionner <i className="bi-arrow-right ms-2"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>

      <Footer showBack />
    </div>
  );
}
