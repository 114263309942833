import { useHistory } from "react-router";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ReactElement } from "react";

interface Props {
  showBack?: boolean;
  showHome?: boolean;
  absolute?: boolean;
  left?: ReactElement;
}

const Footer: React.FC<Props> = ({
  showBack = false,
  showHome = false,
  absolute = true,
  left,
  children,
}) => {
  const history = useHistory();

  return (
    <Container
      fluid
      className={`${
        absolute ? "position-absolute" : ""
      } bottom-0 px-5 pb-3 border-bottom border-5 border-dark`}
    >
      <Row>
        <Col>
          {showBack && (
            <Button
              variant="link"
              className="fs-3 text-decoration-none"
              onPointerUp={() => history.goBack()}
            >
              <i className="bi-arrow-left-circle-fill me-2"></i>
              Retour
            </Button>
          )}
          {showHome && (
            <Button
              variant="link"
              className="fs-3 text-decoration-none"
              onPointerUp={() => history.push("/")}
            >
              <i className="bi-house-door-fill me-2"></i>
              Accueil
            </Button>
          )}
          {left}
        </Col>
        <Col className="text-center">{children}</Col>
        <Col className="text-end">
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => history.push("/help")}
          >
            Aide
            <i className="bi-question-circle-fill ms-2"></i>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
