import { useQuery, UseQueryOptions } from "react-query";
import { getConfig } from "../config";

async function getCategories(): Promise<Category[]> {
  const { apiUrl, apiKey } = getConfig();
  let url = `${apiUrl}/inventory/categories/`;
  const headers = { "X-Locker-Token": apiKey };
  const response = await fetch(url, { headers });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export default function useGetCategories(options?: UseQueryOptions) {
  return useQuery("categories", getCategories, options as any);
}
