import { Button, Container } from "react-bootstrap";
import { useHistory } from "react-router";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Code } from "./Code";

import { ReactComponent as LogoSvg } from "./logo.svg";

export function Home() {
  const history = useHistory();
  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header>
        <LogoSvg height={100} width={200} />
        <p className="lead">
          Casier pour la location et la distribution des outils
        </p>
      </Header>

      <Container className="mt-3">
        <Code />
      </Container>

      <Footer
        left={
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => history.push("/refill")}
          >
            Remplissage
          </Button>
        }
      />
    </div>
  );
}
