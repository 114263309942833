import { Container } from "react-bootstrap";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";

export function Return() {
  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title="Retour d'outils brisés" />

      <Container className="mt-5 p-3 w-75">
        <div className="mt-5 border-start border-3 rounded border-primary bg-surface-variant text-on-surface text-center shadow px-5 py-4">
          <p className="fw-light fs-4 my-3">
            Veuillez déposer votre outil dans la case ouverte.
          </p>
        </div>
      </Container>

      <Footer showHome />
    </div>
  );
}
