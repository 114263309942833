import { useQuery, UseQueryOptions } from "react-query";
import { getConfig } from "../config";

async function getLocker(): Promise<ExpediboxLocker> {
  const { expediboxApiUrl, expediboxApiKey, expediboxLockerId } = getConfig();
  let url = `${expediboxApiUrl}/lockers/${expediboxLockerId}/`;
  const headers = { "X-App-Token": expediboxApiKey };
  const response = await fetch(url, { headers });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export function useGetExpediboxLocker(options?: UseQueryOptions) {
  return useQuery(["expediboxLocker"], getLocker, options as any);
}
