import { useQuery, UseQueryOptions } from "react-query";
import { getConfig } from "../config";

async function getCurrentLocker(): Promise<Locker> {
  const { apiUrl, apiKey } = getConfig();
  let url = `${apiUrl}/lockers/current/`;
  const headers = { "X-Locker-Token": apiKey };
  const response = await fetch(url, { headers });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export default function useGetCurrentLocker(options?: UseQueryOptions) {
  return useQuery("currentLocker", getCurrentLocker, options as any);
}
