import { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { useCreateLockerItem } from "../../hooks/useCreateLockerItem";
import { useDeleteLockerItem } from "../../hooks/useDeleteLockerItem";
import { useUpdateLockerItem } from "../../hooks/useUpdateLockerItem";

interface Params {
  show: boolean;
  onHide: () => any;
  item: LockerItem;
}

export function RefillModal({ show, onHide, item }: Params) {
  const queryClient = useQueryClient();
  const [quantity, setQuantity] = useState(item?.quantity || 0);

  useEffect(() => setQuantity(item?.quantity || 0), [item]);

  const onSuccess = () => {
    queryClient.invalidateQueries(["lockerItems"]);
    onHide();
  };

  const updateLockerItem = useUpdateLockerItem({ onSuccess });
  const createLockerItem = useCreateLockerItem({ onSuccess });
  const deleteLockerItem = useDeleteLockerItem({ onSuccess });

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Quantité dans la case</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row justify-content-center">
          <Button
            className="col-auto"
            size="lg"
            onClick={() => setQuantity(quantity > 0 ? quantity - 1 : 0)}
          >
            <i className="bi-dash-lg"></i>
          </Button>
          <div className="col-auto">
            <Form.Control
              className="text-center"
              type="number"
              value={quantity}
              min="0"
              max="100"
              size="lg"
              readOnly
            />
          </div>
          <Button
            className="col-auto"
            size="lg"
            onClick={() => setQuantity(quantity + 1)}
          >
            <i className="bi-plus-lg"></i>
          </Button>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => onHide()}
          disabled={updateLockerItem.isLoading}
        >
          Annuler
        </Button>
        <Button
          variant="primary"
          disabled={updateLockerItem.isLoading || item.quantity === quantity}
          onClick={() => {
            if (!item.id && quantity > 0) {
              createLockerItem.mutate({ ...item, quantity });
            } else if (item.id && quantity > 0) {
              updateLockerItem.mutate({ id: item.id, quantity });
            } else if (item.id) {
              deleteLockerItem.mutate(item.id);
            }
          }}
        >
          Enregistrer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
