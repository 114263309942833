import { useHistory, useLocation } from "react-router";
import { Container, Table, Button, Col, Row } from "react-bootstrap";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { useState } from "react";
import { useCreateOrder } from "../../hooks/useCreateOrder";
import { useDeleteLockerItem } from "../../hooks/useDeleteLockerItem";
import { useUpdateLockerItem } from "../../hooks/useUpdateLockerItem";
import useOpenDoor from "../../hooks/useOpenDoor";
import { Fragment } from "react";
import { useCreateLoan } from "../../hooks/useCreateLoan";
import { useCreateRepair } from "../../hooks/useCreateRepair";

interface LocationProps {
  product: Product;
  variant: Variant;
  worker: Worker;
  lockerItem: LockerItem;
}

const Reasons = {
  NEW_TOOL: [
    "Nouvelle méthode de travail",
    "Exigence santé et sécurité",
    "Manquant dans le coffre à outils",
    "Nécessite plus d’un exemplaire",
    "Autre",
  ],
  REPLACEMENT: ["Bris", "Vol", "Perte"],
};

export function Checkout() {
  const history = useHistory();
  const [reason, setReason] = useState("");
  const location = useLocation<LocationProps>();
  const product = location.state?.product;
  const variant = location.state?.variant;
  const worker = location.state?.worker;
  const lockerItem = location.state?.lockerItem;
  const openDoor = useOpenDoor();
  const createOrder = useCreateOrder();
  const createLoan = useCreateLoan();
  const deleteLockerItem = useDeleteLockerItem();
  const updateLockerItem = useUpdateLockerItem();
  const createRepair = useCreateRepair();

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title="Commande" />

      <Container className="mt-3 rounded bg-white shadow-sm p-2">
        <Table className="mb-0">
          <tbody>
            <tr className="align-middle">
              <td style={{ minWidth: 170, maxWidth: 170 }}>
                <div
                  className="rounded"
                  style={{
                    width: 150,
                    height: 150,
                    backgroundImage: `url(${product.image})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </td>
              <td className="w-100">
                <h6>
                  {product.name}{" "}
                  <small className="text-muted">{product.category?.name}</small>
                </h6>
                {variant.name}
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>

      <Container
        className="mt-3 rounded bg-white shadow p-4"
        style={{ minHeight: 50, maxHeight: 615, overflow: "overlay" }}
      >
        <h3 className="fw-light mb-3">Motif de la commande</h3>

        <Row>
          <Col>
            <p className="lead">Nouvel outil:</p>

            <div className="btn-group-vertical">
              {Reasons.NEW_TOOL.map((r, idx) => (
                <Fragment key={`NEW_TOOL-${idx}`}>
                  <input
                    type="radio"
                    className="btn-check"
                    checked={r === reason}
                    readOnly
                  />
                  <label
                    className="btn btn-outline-primary btn-lg"
                    onClick={() => setReason(r)}
                  >
                    {r}
                  </label>
                </Fragment>
              ))}
            </div>
          </Col>

          <Col>
            <p className="lead">Remplacement:</p>
            <div className="btn-group-vertical" style={{ width: 250 }}>
              {Reasons.REPLACEMENT.map((r, idx) => (
                <Fragment key={`REPLACEMENT-${idx}`}>
                  <input
                    type="radio"
                    className="btn-check"
                    checked={r === reason}
                    readOnly
                  />
                  <label
                    className="btn btn-outline-primary btn-lg"
                    onClick={() => setReason(r)}
                  >
                    {r}
                  </label>
                </Fragment>
              ))}
            </div>
          </Col>
        </Row>
      </Container>

      <div className="text-center mt-5">
        <Button
          size="lg"
          className="p-3"
          disabled={!reason}
          onPointerUp={async () => {
            let repair: Repair;

            if (product.repairable && reason === Reasons.REPLACEMENT[0]) {
              repair = await createRepair.mutateAsync({
                sku: variant.sku,
                name: `${product.name} » ${variant.name}`,
                worker: worker.id,
                status: "OPEN",
              });
            }

            createOrder.mutate(
              {
                worker: worker.id,
                store: worker.store.id,
                truck: worker.truck.id,
                line_items: [{ quantity: 1, variant: variant.id }],
                status: lockerItem ? "FULFILLED" : "OPEN",
                reason,
              },
              {
                onSuccess: async () => {
                  if (lockerItem && lockerItem.id) {
                    const options = {
                      onSuccess: async () => {
                        if (product.loanable) {
                          await createLoan.mutateAsync({
                            worker: worker.id,
                            sku: lockerItem.sku,
                            name: lockerItem.name,
                            expedibox_door_id: lockerItem.door_id,
                          });
                        }
                        history.push("/checkout-success", {
                          lockerItem,
                          repair,
                          reason,
                        });
                      },
                    };
                    if (lockerItem.quantity === 1)
                      await deleteLockerItem.mutateAsync(
                        lockerItem.id,
                        options
                      );
                    else
                      await updateLockerItem.mutateAsync(
                        {
                          id: lockerItem.id,
                          quantity: lockerItem.quantity - 1,
                        },
                        options
                      );
                    openDoor.mutate({ id: lockerItem.door_id });
                  } else {
                    history.push("/checkout-success", {
                      lockerItem,
                      repair,
                      reason,
                    });
                  }
                },
              }
            );
          }}
        >
          {lockerItem && product.loanable ? "Emprunter" : "Commander"}
        </Button>
      </div>

      <Footer showBack />
    </div>
  );
}
