import { Container, Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import useGetCurrentLocker from "../../hooks/useGetCurrentLocker";
import useOpenDoor from "../../hooks/useOpenDoor";
import { useUpdateRepair } from "../../hooks/useUpdateRepair";

interface LocationProps {
  lockerItem: LockerItem;
  repair: Repair;
  reason: string;
}

export function CheckoutSuccess() {
  const history = useHistory();
  const location = useLocation<LocationProps>();
  const lockerItem = location.state?.lockerItem;
  const repair = location.state?.repair;
  const reason = location.state?.reason;
  const updateRepair = useUpdateRepair();
  const currentLockerQuery = useGetCurrentLocker();
  const openDoor = useOpenDoor();

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title="Commande complétée" />

      <Container className="mt-5 p-3 w-75">
        <div className="mt-5 border-start border-3 rounded border-primary bg-surface-variant text-on-surface text-center shadow px-5 py-4">
          <div className="display-4 fw-light mb-2">
            Merci <i className="bi bi-patch-check ms-2 text-secondary"></i>
          </div>

          <p className="fw-light fs-4">
            {lockerItem
              ? "Vous pouvez récupérer votre outil."
              : "La commande a été envoyé avec succès et sera traitée dans les plus brefs délais. L'outil vous sera acheminé par le service de magasins."}
          </p>
        </div>

        {reason === "Bris" && !repair && (
          <Container className="mt-5 p-0 text-center">
            <i className="bi-exclamation-circle-fill me-2"></i>
            <span className="">
              Il ne s'agit pas d'un outil réparable, vous pouvez donc en
              disposer.
            </span>
          </Container>
        )}

        {repair && (
          <Container className="mt-5 p-0">
            <h3 className="text-center">Cet outil est réparable</h3>
            <div className="mt-4 d-grid gap-4">
              <Button
                className="shadow-sm"
                variant="secondary"
                size="lg"
                onPointerUp={() => {
                  updateRepair.mutate(
                    {
                      id: repair.id,
                      status: "RETURNED",
                    },
                    {
                      onSuccess: () => {
                        if (currentLockerQuery.data) {
                          const position =
                            currentLockerQuery.data.repair_door_position;
                          openDoor.mutate({ position });
                          history.push("/return");
                        }
                      },
                    }
                  );
                }}
              >
                Retourner l'outil brisé maintenant (optionnel)
              </Button>
              <Button size="lg" onClick={() => history.push("/")}>
                Retourner l'outil plus tard
              </Button>
            </div>
          </Container>
        )}
      </Container>

      <Footer showHome />
    </div>
  );
}
