import { Table, Container, Placeholder } from "react-bootstrap";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { ProductRow } from "./ProductRow";
import useGetProducts from "../../hooks/useGetProducts";
import { useLocation } from "react-router";

interface LocationProps {
  worker: Worker;
}

export function RepairableProducts() {
  const productsQuery = useGetProducts({ repairable: true });
  const location = useLocation<LocationProps>();
  const worker = location.state?.worker;

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0 d-flex flex-column">
      <Header title="Sélectionnez un produit" />

      <div className="flex-grow-1 d-flex flex-column">
        <Container
          className="mt-3 rounded bg-white shadow-sm p-2 flex-grow-1"
          style={{ height: 0, overflow: "overlay" }}
        >
          {productsQuery.isLoading && (
            <>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
            </>
          )}
          <Table className="mb-0">
            <tbody>
              {productsQuery.data?.map((product) => (
                <ProductRow
                  key={product.id}
                  product={product}
                  worker={worker}
                />
              ))}
            </tbody>
          </Table>
        </Container>
      </div>

      <Footer absolute={false} showBack />
    </div>
  );
}
