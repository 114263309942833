import { useQuery, UseQueryOptions } from "react-query";
import { getConfig } from "../config";

async function getLockerItems(): Promise<LockerItem[]> {
  const { expediboxApiUrl, expediboxApiKey, expediboxLockerId } = getConfig();
  let url = `${expediboxApiUrl}/locker_items/?locker_id=${expediboxLockerId}`;
  const headers = { "X-App-Token": expediboxApiKey };
  const response = await fetch(url, { headers });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export function useGetLockerItems(options?: UseQueryOptions) {
  if (!options) {
    options = { staleTime: 0, cacheTime: 0 };
  }
  return useQuery("lockerItems", getLockerItems, options as any);
}
