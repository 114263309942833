import { useMutation, UseQueryOptions } from "react-query";
import { getConfig } from "../config";

async function updateWorker(data: WorkerUpdate) {
  const { apiUrl, apiKey } = getConfig();
  let url = `${apiUrl}/workforce/workers/${data.id}/`;
  const headers = {
    "X-Locker-Token": apiKey,
    "Content-Type": "application/json",
  };
  const response = await fetch(url, {
    headers,
    method: "PATCH",
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export function useUpdateWorker(options?: UseQueryOptions) {
  return useMutation(
    (data: WorkerUpdate) => updateWorker(data),
    options as any
  );
}
