import { Table, Container, Placeholder } from "react-bootstrap";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import Keyboard from "react-simple-keyboard";
import Fuse from "fuse.js";

import { useMemo, useState } from "react";
import { ProductRow } from "./ProductRow";
import useGetProducts from "../../hooks/useGetProducts";

import "react-simple-keyboard/build/css/index.css";
import { useGetLockerItems } from "../../hooks/useGetLockerItems";
import { useLocation } from "react-router";
import useGetCategories from "../../hooks/useGetCategories";
import { Fragment } from "react";

const MAX_ITEMS = 25;

const layout = {
  default: [
    "Q W E R T Y U I O P {bksp}",
    "A S D F G H J K L",
    "Z X C V B N M",
    "{space}",
  ],
};

const fuseOptions = {
  threshold: 0.3,
  keys: [
    "name",
    "variants.name",
    {
      name: "description",
      weight: 0.3,
    },
  ],
};

function filterAvailableProducts(
  products: Product[],
  lockerItems: LockerItem[]
) {
  const skus = lockerItems.map((li) => li.sku);
  return products.reduce((prev, curr) => {
    if (curr.variants.some((v) => skus.includes(v.sku))) {
      prev.push(curr);
    }
    return prev;
  }, [] as Product[]);
}

interface LocationProps {
  worker: Worker;
}

export function Products() {
  const [filter, setFilter] = useState("");
  const [category, setCategory] = useState<number | null>(null);
  const [availableFilter, setAvailableFilter] = useState(true);
  const productsQuery = useGetProducts();
  const categoriesQuery = useGetCategories();
  const lockerItemsQuery = useGetLockerItems();
  const location = useLocation<LocationProps>();
  const worker = location.state?.worker;

  const fuseIndex = useMemo(
    () => Fuse.createIndex(fuseOptions.keys, productsQuery.data || []),
    [productsQuery.data]
  );

  const productItems = useMemo(() => {
    if (!productsQuery.data) return [];
    let products = productsQuery.data;

    if (category) {
      products = products.filter(
        (product) => product.category?.id === category
      );
    }

    if (availableFilter) {
      if (!lockerItemsQuery.data) return [];
      products = filterAvailableProducts(products, lockerItemsQuery.data);
      if (!filter)
        return products.map((item) => ({
          item,
          matches: [],
          score: 1,
        }));
      return new Fuse(products, fuseOptions).search(filter);
    }

    if (!filter)
      return products
        .slice(0, availableFilter ? 1000 : MAX_ITEMS)
        .map((item) => ({
          item,
          matches: [],
          score: 1,
        }));

    return new Fuse(products, fuseOptions, fuseIndex).search(filter, {
      limit: MAX_ITEMS,
    });
  }, [
    productsQuery.data,
    lockerItemsQuery.data,
    filter,
    fuseIndex,
    availableFilter,
    category,
  ]);

  const availableProducts = useMemo(() => {
    if (!lockerItemsQuery.data) return [];
    const p = productItems.map((p) => p.item);
    return filterAvailableProducts(p, lockerItemsQuery.data);
  }, [productItems, lockerItemsQuery.data]);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0 d-flex flex-column">
      <Header title="Sélectionnez un produit" />

      <div className="flex-grow-1 d-flex flex-column">
        <Container className="mt-4 p-0">
          <div className="d-inline-flex align-items-center">
            <div className="btn-group">
              <input
                type="radio"
                className="btn-check"
                checked={category === null}
                readOnly
              />
              <label
                className="btn btn-outline-primary"
                onClick={() => setCategory(null)}
              >
                Toutes
              </label>
              {categoriesQuery.data &&
                categoriesQuery.data.map((c) => (
                  <Fragment key={c.id}>
                    <input
                      type="radio"
                      className="btn-check"
                      checked={category === c.id}
                      readOnly
                      key={c.id}
                    />
                    <label
                      className="btn btn-outline-primary"
                      onClick={() => setCategory(c.id)}
                    >
                      {c.name}
                    </label>
                  </Fragment>
                ))}
            </div>

            <div className="form-check form-switch ms-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="available"
                checked={availableFilter}
                onChange={(e) => setAvailableFilter(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="available">
                Disponible dans le casier
              </label>
            </div>
          </div>
        </Container>

        <Container
          className="mt-3 rounded bg-white shadow-sm p-2 flex-grow-1"
          style={{ height: 0, overflow: "overlay" }}
        >
          {productsQuery.isLoading && (
            <>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
            </>
          )}
          <Table className="mb-0">
            <tbody>
              {productItems?.map((product) => (
                <ProductRow
                  key={product.item.id}
                  product={product.item}
                  worker={worker}
                  available={availableProducts.some(
                    (p) => p.id === product.item.id
                  )}
                />
              ))}
            </tbody>
          </Table>
        </Container>

        <Container className="my-3 rounded bg-white shadow-sm p-2">
          <input
            type="text"
            className="form-control form-control-lg mb-2"
            placeholder="Recherche"
            readOnly
            value={filter}
          />
          <Keyboard
            layout={layout}
            onChange={(code: string) => setFilter(code)}
          />
        </Container>
      </div>

      <Footer absolute={false} showBack />
    </div>
  );
}
