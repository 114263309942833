import { useMutation, UseQueryOptions } from "react-query";
import { getConfig } from "../config";

async function updateLockerItem(data: LockerItemUpdate) {
  const { expediboxApiUrl, expediboxApiKey } = getConfig();
  let url = `${expediboxApiUrl}/locker_items/${data.id}/`;
  const headers = {
    "X-App-Token": expediboxApiKey,
    "Content-Type": "application/json",
  };
  const response = await fetch(url, {
    headers,
    method: "PATCH",
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error("Network Error");
  }
}

export function useUpdateLockerItem(options?: UseQueryOptions) {
  return useMutation(
    (data: LockerItemUpdate) => updateLockerItem(data),
    options as any
  );
}
