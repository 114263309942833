import { Table, Button } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import useGetCurrentLocker from "../../hooks/useGetCurrentLocker";
import useOpenDoor from "../../hooks/useOpenDoor";
import { useUpdateRepair } from "../../hooks/useUpdateRepair";

interface Props {
  worker: Worker;
}

export function Repairs({ worker }: Props) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const openDoor = useOpenDoor();
  const updateRepair = useUpdateRepair();
  const currentLockerQuery = useGetCurrentLocker();

  return (
    <Table className="mb-0" borderless>
      <tbody>
        {worker.repairs
          .filter((l) => l.status === "OPEN")
          .map((repair) => (
            <tr key={repair.id}>
              <td style={{ width: "100%" }}>{repair.name}</td>
              <td>
                <Button
                  disabled={!currentLockerQuery.data}
                  onClick={() => {
                    updateRepair.mutate(
                      { id: repair.id, status: "RETURNED" },
                      {
                        onSuccess: () => {
                          if (currentLockerQuery.data) {
                            openDoor.mutate({
                              position:
                                currentLockerQuery.data.repair_door_position,
                            });
                          }

                          queryClient.invalidateQueries([
                            "workers",
                            worker.number,
                          ]);

                          history.push("/return");
                        },
                      }
                    );
                  }}
                >
                  Retourner
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}
