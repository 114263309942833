import { Table, Container, Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Loans } from "./Loans";
import { Repairs } from "./Repairs";

interface LocationProps {
  worker: Worker;
}

export function Profile() {
  const history = useHistory<LocationProps>();
  const worker = history.location.state?.worker;

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header
        title={
          <>
            <i className="bi-person-circle me-3"></i>Profil de l'employé
          </>
        }
      />

      <Container className="mt-5">
        <Container className="mx-auto bg-white shadow p-4">
          <Table className="mb-0" borderless>
            <tbody>
              <tr>
                <th style={{ width: 200 }}>Nom</th>
                <td>
                  {worker.first_name} {worker.last_name}
                </td>
              </tr>
              <tr>
                <th style={{ width: 200 }}>Numéro d'employé</th>
                <td>#{worker.number}</td>
              </tr>
              <tr>
                <th style={{ width: 200 }}>Magasin</th>
                <td>{worker.store?.name || "Aucun"}</td>
              </tr>
              <tr>
                <th style={{ width: 200 }}>Camion</th>
                <td>{worker?.truck?.number || "Aucun"}</td>
              </tr>
            </tbody>
          </Table>
        </Container>

        {worker.loans.some((l) => l.active) && (
          <Container className="mx-auto bg-white shadow p-4 mt-4">
            <h3>Emprunts</h3>
            <Loans worker={worker} />
          </Container>
        )}

        {worker.repairs.some((l) => l.status === "OPEN") && (
          <Container className="mx-auto bg-white shadow p-4 mt-4">
            <h3>Réparations en attente</h3>
            <Repairs worker={worker} />
          </Container>
        )}

        <Container className="mt-5 p-0">
          <div className="d-grid gap-3">
            <Button
              className="shadow-sm"
              variant="primary"
              size="lg"
              onPointerUp={() => history.push("/truck", { worker })}
            >
              Nouvelle commande
            </Button>
            <Button
              className="shadow-sm"
              variant="secondary"
              size="lg"
              onPointerUp={() =>
                history.push("/repairable-products", { worker })
              }
            >
              Retourner un outil brisé
            </Button>
          </div>
        </Container>
      </Container>

      <Footer showBack />
    </div>
  );
}
