import { Badge } from "react-bootstrap";
import { useHistory } from "react-router";

interface Props {
  product: Product;
  available: boolean;
  worker: Worker;
}

export function ProductRow({ product, available, worker }: Props) {
  const history = useHistory();
  return (
    <tr
      className="align-middle"
      onPointerUp={() => history.push("/variants", { product, worker })}
    >
      <td style={{ minWidth: 170, maxWidth: 170 }}>
        <div
          className="rounded"
          style={{
            width: 150,
            height: 150,
            backgroundImage: `url(${product.image})`,
            backgroundSize: "cover",
          }}
        ></div>
      </td>
      <td className="w-100">
        <h6>
          {product.name}{" "}
          <small className="text-muted">{product.category?.name}</small>
        </h6>
        <p className="mb-2">{product.description}</p>
        <Badge bg="primary me-2">{product.variants.length} variantes</Badge>

        {available && <Badge bg="secondary">Disponible</Badge>}
      </td>
    </tr>
  );
}
