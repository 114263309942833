import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Numpad } from "../../components/Numpad";
import useGetWorkers from "../../hooks/useGetWorkers";

export function Code() {
  const [code, setCode] = useState("");
  const [invalid, setInvalid] = useState(false);
  const history = useHistory();
  const workerQuery = useGetWorkers(code, {
    enabled: !!code,
    staleTime: 0,
    cacheTime: 0,
  });

  useEffect(() => {
    if (workerQuery.data && workerQuery.data.length) {
      setInvalid(false);
      const worker = workerQuery.data[0];
      history.push("/profile", { worker });
    } else if (workerQuery.data) {
      setInvalid(true);
    }
  }, [workerQuery.data, history]);

  return (
    <>
      {invalid && (
        <div className="border-start border-3 rounded border-danger bg-surface-variant text-on-surface text-center shadow px-5 py-2">
          <p className="fw-light fs-4 my-2">
            Code invalide. Veuillez réessayer.
          </p>
        </div>
      )}
      <Numpad
        className="mt-4 bg-surface-variant shadow-sm p-4 rounded"
        placeholder={invalid ? "Code Invalide" : "Code Employé"}
        loading={workerQuery.isLoading}
        onSubmit={(code) => setCode(code)}
      />
    </>
  );
}
