import { useMutation, UseQueryOptions } from "react-query";
import { getConfig } from "../config";

async function deleteLockerItem(id: string) {
  const { expediboxApiUrl, expediboxApiKey } = getConfig();
  let url = `${expediboxApiUrl}/locker_items/${id}/`;
  const headers = { "X-App-Token": expediboxApiKey };
  const response = await fetch(url, { headers, method: "DELETE" });
  if (!response.ok) {
    throw new Error("Network Error");
  }
}

export function useDeleteLockerItem(options?: UseQueryOptions) {
  return useMutation((id: string) => deleteLockerItem(id), options as any);
}
