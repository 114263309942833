import { Table, Button } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { useCreateLockerItem } from "../../hooks/useCreateLockerItem";
import { useGetLockerItems } from "../../hooks/useGetLockerItems";
import useOpenDoor from "../../hooks/useOpenDoor";
import { useUpdateLoan } from "../../hooks/useUpdateLoan";
import { useUpdateLockerItem } from "../../hooks/useUpdateLockerItem";

interface Props {
  worker: Worker;
}

export function Loans({ worker }: Props) {
  const openDoor = useOpenDoor();
  const updateLoan = useUpdateLoan();
  const queryClient = useQueryClient();
  const history = useHistory();
  const lockerItemsQuery = useGetLockerItems();
  const updateLockerItem = useUpdateLockerItem();
  const createLockerItem = useCreateLockerItem();

  return (
    <Table className="mb-0" borderless>
      <tbody>
        {worker.loans
          .filter((l) => l.active)
          .map((loan) => (
            <tr key={loan.id}>
              <td style={{ width: "100%" }}>{loan.name}</td>
              <td>
                <Button
                  disabled={
                    lockerItemsQuery.isLoading ||
                    updateLockerItem.isLoading ||
                    createLockerItem.isLoading ||
                    updateLoan.isLoading
                  }
                  onClick={() => {
                    if (!lockerItemsQuery.data) return;
                    const item = lockerItemsQuery.data.find(
                      (item) =>
                        item.sku === loan.sku &&
                        item.door_id === loan.expedibox_door_id
                    );
                    if (item && item.id) {
                      updateLockerItem.mutate({
                        id: item.id,
                        quantity: item.quantity + 1,
                      });
                    } else {
                      createLockerItem.mutate({
                        door_id: loan.expedibox_door_id,
                        name: loan.name,
                        sku: loan.sku,
                        quantity: 1,
                      });
                    }
                    updateLoan.mutate(
                      { id: loan.id, active: false },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries([
                            "workers",
                            worker.number,
                          ]);
                          openDoor.mutate({ id: loan.expedibox_door_id });
                          history.push("/return-loan");
                        },
                      }
                    );
                  }}
                >
                  Retourner
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}
