import { useQuery, UseQueryOptions } from "react-query";
import { getConfig } from "../config";

async function getRepairs(status?: string): Promise<Repair[]> {
  const { apiUrl, apiKey } = getConfig();
  let url = `${apiUrl}/repairs/`;
  if (status) {
    url += `?status=${status}`;
  }
  const headers = { "X-Locker-Token": apiKey };
  const response = await fetch(url, { headers });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export default function useGetRepairs(
  status?: string,
  options?: UseQueryOptions
) {
  return useQuery(
    ["repairs", status],
    () => getRepairs(status),
    options as any
  );
}
