import { Spinner } from "react-bootstrap";
import useGetCategories from "../../hooks/useGetCategories";
import useGetCurrentLocker from "../../hooks/useGetCurrentLocker";
import { useGetExpediboxLocker } from "../../hooks/useGetExpediboxLocker";
import useGetProducts from "../../hooks/useGetProducts";
import useGetRefillMinimums from "../../hooks/useGetRefillMinimum";

export const Preoloader: React.FC = ({ children }) => {
  const productsQuery = useGetProducts();
  const repairableProductsQuery = useGetProducts({ repairable: true });
  const categoriesQuery = useGetCategories();
  const lockerQuery = useGetCurrentLocker();
  const expediboxLockerQuery = useGetExpediboxLocker();
  const refillMinimumsQuery = useGetRefillMinimums();
  const isLoading =
    productsQuery.isLoading ||
    lockerQuery.isLoading ||
    categoriesQuery.isLoading ||
    expediboxLockerQuery.isLoading ||
    repairableProductsQuery.isLoading ||
    refillMinimumsQuery.isLoading;
  return (
    <>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center h-100">
          <Spinner animation="border" />
        </div>
      ) : (
        children
      )}
    </>
  );
};
