import { Container } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Numpad } from "../../components/Numpad";
import { useUpdateWorker } from "../../hooks/useUpdateWorker";

interface LocationProps {
  worker: Worker;
}

export function Truck() {
  const history = useHistory();
  const location = useLocation<LocationProps>();
  const worker = location.state?.worker;
  const updateWorker = useUpdateWorker({
    onSuccess: (worker) => {
      history.push("/products", { worker });
    },
  });

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={<i className="bi-truck"></i>} />

      <Container className="mt-5">
        <Numpad
          className="bg-surface-variant shadow-sm p-4 rounded"
          placeholder="Numéro de camion"
          onSubmit={(code) => {
            const truck_number = Number.parseInt(code);
            if (worker.truck.number !== truck_number) {
              updateWorker.mutate({
                id: worker.id,
                truck_number,
              });
            } else {
              history.push("/products", { worker });
            }
          }}
        />
      </Container>

      <Footer showBack />
    </div>
  );
}
